@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body,
#root {
    height: 100%;
    font-family: Agbalumo, serif;
}

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
